import React from "react";

import { ChartDatePoint } from "graphql/types";
import { formatMoney } from "@puzzle/utils";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";

import Calculating from "./Calculating";
import { chartDataPointPercentageDiff } from "./percentageDiffUtil";
import LoadingMetrics from "./LoadingMetrics";
import { ChartDatePointDataBarGraph } from "./BarChartGraphs";
import { BasicGraphTileContainer } from "./BasicGraphTileContainer";
import { EmptyGraph, EmptyGraphVariant } from "./EmptyGraph";

export const RevenueGraphTile = ({
  title,
  data,
  chartDatePointDataKey,
  loading,
  initialIngestOngoing,
  isFeatureGated,
}: {
  title: string;
  data?: ChartDatePoint[];
  chartDatePointDataKey: string;
  loading?: boolean;
  initialIngestOngoing?: boolean;

  isFeatureGated?: boolean;
}) => {
  let formattedValue;
  let percentageIncrease;
  if (data && data.length >= 0) {
    const lastValue = data[data.length - 1]?.values.find(
      (x) => x.key === chartDatePointDataKey
    )?.value;
    formattedValue = formatMoney({ amount: parseFloat(lastValue || "0") }, { truncateValue: true });

    percentageIncrease = isPosthogFeatureFlagEnabled(FeatureFlag.DashboardShowPercentageChanged)
      ? chartDataPointPercentageDiff(data || [], chartDatePointDataKey)
      : undefined;
  }

  const startDate = (data && data.length >= 0 && data[0]?.date) || undefined;
  const endDate = (data && data.length >= 0 && data[data.length - 1]?.date) || undefined;

  return (
    <BasicGraphTileContainer
      title={title}
      valueString={formattedValue}
      percentageIncrease={percentageIncrease}
      isFeatureGated={isFeatureGated}
      startDate={startDate}
      endDate={endDate}
      initialIngestOngoing={initialIngestOngoing}
    >
      {initialIngestOngoing ? (
        <>
          <Calculating css={{ marginLeft: "$2" }} />
          <EmptyGraph variant={EmptyGraphVariant.BARS_THICK} />
        </>
      ) : loading ? (
        <div style={{ paddingLeft: "16px" }}>
          <LoadingMetrics />
        </div>
      ) : (
        <ChartDatePointDataBarGraph data={data} chartDatePointDataKey={chartDatePointDataKey} />
      )}
    </BasicGraphTileContainer>
  );
};
