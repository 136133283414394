import { useLocalStorage } from "react-use";
import { BurnFormula, RevenueFormula } from "../Metrics/shared";

export const useStickyFormulas = ({
  burnFormulaDefault = BurnFormula.TotalBurn,
  revenueFormulaDefault = RevenueFormula.ARR,
  showThreeMonthAverageDefault = true,
  customBurnValueDefault = "",
} = {}) => {
  const [_burnFormula, setBurnFormula] = useLocalStorage(
    "pz:dashboard-burn-forumla",
    burnFormulaDefault
  );
  const [_revenueFormula, setRevenueFormula] = useLocalStorage(
    "pz:dashboard-revenue-formula",
    revenueFormulaDefault
  );
  const [_showThreeMonthAverage, setShowThreeMonthAverage] = useLocalStorage(
    "pz:dashboard-3-month-avg",
    showThreeMonthAverageDefault
  );

  const [customBurnValue, setCustomBurnValue] = useLocalStorage(
    "pz:dashboard-custom-burn",
    customBurnValueDefault
  );

  return {
    burnFormula:
      _burnFormula && Object.values(BurnFormula).includes(_burnFormula)
        ? _burnFormula
        : burnFormulaDefault,
    revenueFormula:
      _revenueFormula && Object.values(RevenueFormula).includes(_revenueFormula)
        ? _revenueFormula
        : revenueFormulaDefault,
    showThreeMonthAverage: _showThreeMonthAverage ?? showThreeMonthAverageDefault,
    customBurnValue,
    setBurnFormula,
    setRevenueFormula,
    setShowThreeMonthAverage,
    setCustomBurnValue,
  };
};
