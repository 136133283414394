import React from "react";

import { Arrow } from "@puzzle/icons";
import { S, Button, Text } from "ve";
import links from "lib/links";

import { usePOMChecklistUpdate } from "./usePOMChecklist";
import { rootStyle } from "./BookCall.css";

export const BookCall = ({ onContinue }: { onContinue: () => void }) => {
  const { markOnboardingCallAsChecked } = usePOMChecklistUpdate();

  const handleContinue = (bookOnboardingCall?: boolean) => {
    markOnboardingCallAsChecked();
    if(bookOnboardingCall){
      window.open(links.freeConciergeOnboarding, "_blank", "noopener noreferrer");
    }
    onContinue();
  };

  return (
    <div className={rootStyle}>
      <div>
        <Text variant="headingM" color="white">
          Book your concierge onboarding call
        </Text>
      </div>
      <Text>
        Gain the peace of mind knowing your Puzzle account is setup for success. Our experts would
        love to understand your use case and goals so we can help you get started on the right foot.
      </Text>
      <Button
        onClick={() => handleContinue(true)}
        css={{ alignSelf: "center" }}
        fullWidth={true}
      >
        Book your onboarding call
      </Button>

      <Button
        onClick={() => handleContinue()}
        css={{ alignSelf: "flex-end", marginTop: "auto", marginBottom: S.$1 }}
        suffixElement={<Arrow size={16} color="$black" />}
      >
        Continue
      </Button>
    </div>
  );
};
