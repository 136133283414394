import React from "react";

import useAppRouter from "lib/useAppRouter";
import { Route } from "lib/routes";
import { ChartRising, BookOpen, External } from "@puzzle/icons";

import { color, S, Text, UnstyledButton, Button } from "ve";

import { usePOMChecklistUpdate } from "./usePOMChecklist";

import {
  buttonContainerStyle,
  rootStyle,
  subtitleTextContainerStyle,
  buttonTopSectionStyle,
  buttonBottomSectionStyle,
  iconCircleStyle,
} from "./SneakPeek.css";

const Panel = ({
  title,
  subtitle,
  route,
  icon,
  onClose,
}: {
  title: string;
  subtitle: string;
  route: Route;
  icon: React.ReactElement;
  onClose: () => void;
}) => {
  const { goToPath } = useAppRouter();
  const handleClick = () => {
    onClose();
    goToPath(route);
  };

  return (
    <div>
      <UnstyledButton
        onClick={handleClick}
        css={{
          width: "100%",
          borderRadius: "8px",
          backgroundColor: color.gray700,
          padding: "16px",
        }}
      >
        <div className={buttonTopSectionStyle}>
          <div className={iconCircleStyle}>{icon}</div>

          <Text variant="bodyM" color="gray100">
            {title}
          </Text>
        </div>
        <div className={buttonBottomSectionStyle}>
          <Text variant="bodyXS" color="gray400">
            {subtitle}
          </Text>
          <External width={14} height={14} color={color.gray500} />
        </div>
      </UnstyledButton>
    </div>
  );
};

export const SneakPeek = ({ onClose }: { onClose: () => void }) => {
  const { markSneakPeekAsChecked } = usePOMChecklistUpdate();
  const handleClick = async () => {
    await markSneakPeekAsChecked();
    onClose();
  };

  return (
    <div className={rootStyle}>
      <Text variant="bodyXL" color="white">
        See a sneak peek of your draft financials
      </Text>
      <div className={subtitleTextContainerStyle}>
        <Text>
          Note: We’re still ingesting and drafting your financials. While these will provide
          directional insight that you can action today, accuracy will increase as you complete
          outstanding transaction categorizations and confirm opening bank balances.
        </Text>
      </div>
      <div className={buttonContainerStyle}>
        <Panel
          title="See my financial metrics"
          subtitle="View your dashboard"
          route={Route.home}
          icon={<ChartRising size={12} color={color.greenalpha} />}
          onClose={handleClick}
        />
        <Panel
          title="Understand my spending"
          subtitle="Check out Spend Explorer"
          route={Route.spending}
          icon={<ChartRising size={12} color={color.greenalpha} />}
          onClose={handleClick}
        />
        <Panel
          title="View your accounting checklist"
          subtitle="Go to your Monthly Checklist"
          route={Route.checklist}
          icon={<BookOpen size={12} color={color.greenalpha} />}
          onClose={handleClick}
        />
      </div>
      <Button
        onClick={handleClick}
        css={{ alignSelf: "flex-end", marginTop: "auto", marginBottom: S.$4, marginRight: S.$3 }}
      >
        Done
      </Button>
    </div>
  );
};
