/* eslint-disable react/display-name */
import React, { useEffect } from "react";
import { PrivateLayout } from "components/layout/private";
import { Dashboard } from "components/dashboard/Dashboard";
import { ExtendedNextPage } from "components/common/types";
import useAppRouter from "lib/useAppRouter";
import config from "lib/config";
import { destroyPostLoginRedirectUrl, getPostLoginRedirectUrl } from "lib/cookies";

const DashboardPage: ExtendedNextPage<Record<string, unknown>> = () => {
  const { goToPath } = useAppRouter();

  // TODO remove in favor of login.page.ts's redirect behavior? or choose one.
  // duplicated in VRAP
  useEffect(() => {
    const redirectUrl = getPostLoginRedirectUrl();
    if (redirectUrl) {
      const url = new URL(`${config.ROOT_URL}${redirectUrl}`);
      const query = Object.fromEntries(new URLSearchParams(url.search));
      destroyPostLoginRedirectUrl();
      goToPath(url.pathname, { query });
    }
  }, [goToPath]);

  return <Dashboard />;
};

DashboardPage.getLayout = (page) => {
  // We want a blank but not empty title to show a header with nothing visible, this is the simplest way.
  return (
    <PrivateLayout title=" " documentTitle="Dashboard">
      {page}
    </PrivateLayout>
  );
};

export default DashboardPage;
