import React, { useCallback, useMemo, useState } from "react";
import {
  Bar,
  CartesianGrid,
  Line,
  ResponsiveContainer as RootResponsiveContainer,
  ComposedChart,
  XAxis,
  YAxis,
  Tooltip,
  Cell,
} from "recharts";
import { colors, styled, CSS } from "@puzzle/ui";
import { formatMoney, parseDate, today, useLocalDateFormatter } from "@puzzle/utils";
import { ChartDatePointFragment } from "./graphql.generated";
import { mix } from "polished";
import { BarIcon, LineIcon } from "./icons";
import { useActiveCompany } from "components/companies";
import { useDiagonalMask } from "./shared";
import { CategoricalChartFunc } from "recharts/types/chart/generateCategoricalChart";
import Analytics from "lib/analytics";
import { Box, S, Text, color, vars } from "ve";

const ResponsiveContainer = styled(RootResponsiveContainer, {
  svg: {
    overflow: "visible",
  },
});

const Header = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: "$1",

  textVariant: "$headingS",
  fontWeight: "$heavy",
  color: "$gray200",
  "@m": {
    flexDirection: "row",
  },
});

const Title = styled("div", {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const Legend = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  fontSize: "$bodyXS",
  color: "$gray100",
  gap: "$2h",

  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const LegendItem = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "$1",
  lineHeight: 0,
});

const Root = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "$2h",
  height: "100%",
  padding: "$2 $1h 0",
  borderRight: 0,
  borderBottom: "1px solid #383147",
  "@l": {
    borderBottom: 0,
    borderRight: "1px solid #383147",
  },
});

const Tick = styled("text", {
  fill: "$gray400",
  fontSize: "$bodyXS",
  textAnchor: "middle",
});

const TooltipLegendItem = styled(LegendItem, {
  display: "grid",
  gridTemplateColumns: "16px 1fr",
  color: "$gray200",
  "*:first-child": {
    justifySelf: "center",
  },
});

type Key = "net-burn" | "cash-balance";
const KeyToIcon: Record<Key, React.ReactElement> = {
  "net-burn": <BarIcon color={colors.yellow400} />,
  "cash-balance": <LineIcon color={colors.greenalpha} />,
};
const KeyToLabel: Record<Key, string> = {
  "net-burn": "Net Burn",
  "cash-balance": "Cash Balance",
};

const KeyToColors: Record<Key, { active: string; inactive: string }> = {
  "net-burn": {
    active: colors.yellow400,
    inactive: mix(0.4, "#1b1c29", colors.yellow400),
  },
  "cash-balance": {
    active: colors.greenalpha,
    inactive: mix(0.4, "#1b1c29", colors.greenalpha),
  },
};

export const CashChart = ({
  data,
  width,
  height,
  css,
}: {
  data: ChartDatePointFragment[];
  width?: number | string;
  height?: number | string;
  css?: CSS;
}) => {
  const { timeZone } = useActiveCompany<true>();
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const monthFormatter = useLocalDateFormatter({
    month: "long",
    year: "numeric",
  });

  const currencyFormatter = useMemo(
    () =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        notation: "compact",
        compactDisplay: "short",
        currency: "USD",
        // Doesn't work with "compact"
        // currencySign: "accounting",
      }),
    []
  );

  const isAllBurnNegative = useMemo(
    () =>
      data.every((item) => parseFloat(item.values.find((x) => x.key === "net-burn")!.value) <= 0),
    [data]
  );

  const handleMouseEvent = useCallback<CategoricalChartFunc>((state, event) => {
    if (state.isTooltipActive && typeof state.activeTooltipIndex === "number") {
      setActiveIndex(state.activeTooltipIndex);
    } else {
      setActiveIndex(null);
    }

    if (event.type === "mouseenter") {
      Analytics.dashboardChartHovered({ id: "net-burn" });
    }
  }, []);

  const [largestTickSize, setLargestTickSize] = useState(0);
  const currencyTickFormatter = useCallback(
    (x: string) => {
      const value = parseFloat(x);
      const amount = currencyFormatter.format(Math.abs(value)).toLowerCase();
      const result = value < 0 ? `(${amount})` : amount;
      if (amount.length > largestTickSize) {
        setLargestTickSize(amount.length);
      }
      return result;
    },
    [currencyFormatter, largestTickSize]
  );

  const { maskUrl, mask } = useDiagonalMask();

  return (
    <Root css={css}>
      <Header>
        <Title>Net burn & Cash balance</Title>
        <Legend>
          <LegendItem>
            {KeyToIcon["net-burn"]}
            Net burn
          </LegendItem>
          <LegendItem>
            {KeyToIcon["cash-balance"]}
            Cash balance
          </LegendItem>
        </Legend>
      </Header>
      <ResponsiveContainer width={width} height={height}>
        <ComposedChart
          data={data}
          onMouseEnter={handleMouseEvent}
          onMouseLeave={handleMouseEvent}
          onMouseMove={handleMouseEvent}
          margin={{
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          }}
          barCategoryGap={14}
        >
          {mask}

          <CartesianGrid stroke="#595861" vertical={false} />

          <Tooltip
            wrapperStyle={{ outline: "none" }}
            cursor={false}
            content={({ active, payload }) => {
              if (active && payload && payload.length) {
                return (
                  <Box
                    css={{
                      display: "flex",
                      flexDirection: "column",
                      backgroundColor: color.black,
                      borderRadius: vars.radii[1],
                      borderColor: color.black,
                    }}
                  >
                    <Box
                      css={{
                        borderBottom: `1px solid ${color.gray700}`,
                        padding: `${S["0h"]} ${S["2"]}`,
                      }}
                    >
                      <Text variant="bodyXS" color="gray400">
                        {monthFormatter.format(parseDate(payload[0].payload.date))}
                      </Text>
                    </Box>
                    <Box
                      css={{
                        display: "grid",
                        gridAutoFlow: "row",
                        gridTemplateColumns: "minmax(0, 1fr) minmax(0, 1fr)",
                        flexDirection: "column",
                        padding: S["2"],
                        gap: S["1"],
                      }}
                    >
                      {payload.map(({ name, value }) => {
                        return (
                          <React.Fragment key={name}>
                            <TooltipLegendItem>
                              {KeyToIcon[name as Key]}
                              {KeyToLabel[name as Key]}
                            </TooltipLegendItem>
                            <Text style={{ textAlign: "right", color: color.white }}>
                              {formatMoney({ amount: value as number }, { truncateValue: true })}
                            </Text>
                          </React.Fragment>
                        );
                      })}
                    </Box>
                  </Box>
                );
              }

              return null;
            }}
          />

          <YAxis
            interval={0}
            reversed={isAllBurnNegative}
            yAxisId="net-burn"
            tickFormatter={currencyTickFormatter}
            tick={{ fill: KeyToColors["net-burn"].active }}
            tickLine={false}
            axisLine={false}
            // Display the labels inside the chart
            dy={13}
            dx={10}
            textAnchor="start"
            // Needs to be >0 to display
            width={1}
          />
          <Bar
            isAnimationActive={false}
            fill={KeyToColors["net-burn"].active}
            yAxisId="net-burn"
            name="net-burn"
            maxBarSize={30}
            radius={[4, 4, 0, 0]}
            // TODO make a type-safe flattening util, or getter factory
            dataKey={(row: ChartDatePointFragment) =>
              parseFloat(row.values.find((x) => x.key === "net-burn")!.value)
            }
          >
            {data.map((entry, index) => (
              <Cell
                mask={parseDate(entry.date).compare(today(timeZone)) > 0 ? maskUrl : undefined}
                key={index}
                fill={
                  KeyToColors["net-burn"][
                    activeIndex === index || activeIndex === null ? "active" : "inactive"
                  ]
                }
              />
            ))}
          </Bar>

          <YAxis
            interval={0}
            yAxisId="cash-balance"
            orientation="right"
            tickFormatter={currencyTickFormatter}
            tick={{ fill: KeyToColors["cash-balance"].active }}
            tickLine={false}
            axisLine={false}
            // Display the labels inside the chart
            dy={13}
            dx={-10}
            textAnchor="end"
            // Needs to be >0 to display
            width={1}
          />
          <Line
            isAnimationActive={false}
            stroke={KeyToColors["cash-balance"][activeIndex === null ? "active" : "inactive"]}
            strokeWidth={4}
            dot={{
              fill: "#1B1C29",
              stroke: KeyToColors["cash-balance"][activeIndex === null ? "active" : "inactive"],
              strokeWidth: 4,
              r: 6,
              style: {
                filter: `drop-shadow(rgba(18, 23, 24, 0.5) 0px 0px 5px)`,
              },
            }}
            activeDot={{
              fill: "#1B1C29",
              stroke: KeyToColors["cash-balance"].active,
              strokeWidth: 4,
              r: 6,
              style: {
                // from Figma; box-shadow can't be used with SVG
                // boxShadow: `0px 4px 8px rgba(18, 23, 24, 0.1), 0px 8px 24px rgba(18, 23, 24, 0.2)`,
                filter: `drop-shadow(rgba(18, 23, 24, 0.5) 0px 0px 5px)`,
              },
            }}
            yAxisId="cash-balance"
            name="cash-balance"
            dataKey={(row: ChartDatePointFragment) =>
              parseFloat(row.values.find((x) => x.key === "cash-balance")!.value)
            }
          />

          <XAxis
            dataKey="date"
            dy={10}
            tickLine={false}
            tick={({ payload, ...props }) => {
              return (
                <Tick {...props}>
                  {monthFormatter.customFormat(parseDate(payload.value), "MMM ''yy")}
                </Tick>
              );
            }}
            padding={{
              left: largestTickSize * 10,
              right: largestTickSize * 10,
            }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Root>
  );
};
