import { formatMoney } from "@puzzle/utils";
import React from "react";
import { Box, S, Stack, Text, vars } from "ve";
import { colors } from "ve/theme/colors";
import { EmptyGraph, EmptyGraphVariant } from "./EmptyGraph";
import { BarChartGraphData } from "./BarChartGraphs";
import {
  Bar,
  BarChart,
  Cell,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { ChartDatePoint } from "graphql/types";

const previousColor = "linear-gradient(0deg, rgba(96,71,156,0.25) 0%, rgba(192,143,238,0.25) 100%)";

export const extractChartDatePointData = (
  data: ChartDatePoint[],
  chartDatePointDataKey: string,
  reverseValues?: boolean
): BarChartGraphData[] => {
  const multiplier = reverseValues ? 1 : -1;
  return data.map((x) => ({
    date: x.date,
    value: parseFloat(x.values.find((x) => x.key === chartDatePointDataKey)!.value) * multiplier,
  }));
};

const BurnComparisonTooltip = ({
  currentValue,
  previousValue,
}: {
  currentValue?: number;
  previousValue?: number;
}) => {
  return (
    <Box
      css={{
        display: "flex",
        flexDirection: "column",
        gap: S["$1"],
        padding: S["$1h"],
        backgroundColor: colors.black,
        borderRadius: vars.radii["1"],
        borderColor: colors.black,
      }}
    >
      {!currentValue && !previousValue && (
        <Text variant="bodyXS">There is not enough that to show...</Text>
      )}
      {typeof currentValue === "number" && (
        <Stack
          direction="horizontal"
          css={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Stack direction="horizontal" css={{ alignItems: "center" }} gap={vars.radii["1"]}>
            <Ellipse background={colors.purple500} />
            <Text variant="bodyXS" color="gray300" weight="bold">
              Current
            </Text>
          </Stack>
          <Text variant="bodyXS" color="white" weight="bold">
            {formatMoney({ amount: currentValue as number }, { truncateValue: true })}
          </Text>
        </Stack>
      )}
      {typeof previousValue === "number" && (
        <Stack
          direction="horizontal"
          css={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Stack direction="horizontal" css={{ alignItems: "center" }} gap={vars.radii["1"]}>
            <Ellipse background={previousColor} />
            <Text variant="bodyXS" color="gray300" weight="bold">
              Previous
            </Text>
          </Stack>
          <Text variant="bodyXS" color="white" weight="bold">
            {formatMoney({ amount: previousValue as number }, { truncateValue: true })}
          </Text>
        </Stack>
      )}
    </Box>
  );
};

export const VerticalBarChartGraph = ({
  data,
  axisKey,
}: {
  data?: BarChartGraphData[];
  axisKey: string;
}) => {
  if (!data || data.length === 0) {
    return <EmptyGraph variant={EmptyGraphVariant.BARS_THICK} text="Not enough data to graph" />;
  }

  const lastElementIndex = data.length - 1;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart layout="vertical" data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
        <defs>
          <linearGradient id="current" x1="100%" x2="0">
            <stop offset="0%" stopColor={colors.purple500} stopOpacity={1.0} />
            <stop offset="100%" stopColor={colors.purple900} stopOpacity={1.0} />
          </linearGradient>
          <linearGradient id="previous" x1="100%" x2="0">
            <stop offset="0%" stopColor={colors.purple500} stopOpacity={0.25} />
            <stop offset="100%" stopColor={colors.purple900} stopOpacity={0.25} />
          </linearGradient>
        </defs>
        <XAxis
          type="number"
          domain={([dataMin, dataMax]) => {
            return [dataMin < 0 ? dataMin : 0, dataMax > 0 ? dataMax : 0];
          }}
          hide
        />
        <YAxis type="category" hide />

        <Bar
          isAnimationActive={false}
          name={axisKey}
          dataKey="value"
          barSize={20}
          radius={[4, 4, 4, 4]}
        >
          {data.map((value, index) => {
            return (
              <Cell
                key={`cell-${index}`}
                fill={index === lastElementIndex ? "url(#previous)" : "url(#current)"}
              />
            );
          })}
        </Bar>

        <ReferenceLine x={0} stroke="white" strokeWidth={2} isFront={true} ifOverflow="hidden" />

        <Tooltip
          wrapperStyle={{ outline: "none" }}
          cursor={false}
          content={() => {
            return (
              <BurnComparisonTooltip
                currentValue={data.at(0)?.value}
                previousValue={data.at(1)?.value}
              />
            );
          }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export const Ellipse = ({ background }: { background: string }) => {
  return <div style={{ height: "6px", width: "6px", background, borderRadius: "50%" }}></div>;
};

export const BurnComparisonBarGraph = ({
  data,
  reverseBars,
}: {
  data?: ChartDatePoint[];
  reverseBars?: boolean;
}) => {
  if (!data) {
    return null;
  }

  const mappedData = extractChartDatePointData(data, "net-burn", reverseBars);

  return (
    <div
      style={{
        display: "flex",
        flex: "1 1 auto",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        justifyContent: "end",
      }}
    >
      <div style={{ width: "100%", flex: "0 1 44px", padding: "0 16px" }}>
        <div style={{ flex: "0 1 44px", height: "44px", width: "100%" }}>
          <VerticalBarChartGraph data={mappedData} axisKey="net-burn" />
        </div>
        <div style={{ paddingTop: "4px", display: "flex", gap: "16px" }}>
          <Stack direction="horizontal" css={{ alignItems: "center" }} gap="4px">
            <Ellipse background={colors.purple500} />
            <Text variant="bodyXS" color="gray300" weight="bold">
              Current month
            </Text>
          </Stack>
          <Stack direction="horizontal" css={{ alignItems: "center" }} gap="4px">
            <Ellipse background={previousColor} />
            <Text variant="bodyXS" color="gray300" weight="bold">
              Previous month
            </Text>
          </Stack>
        </div>
      </div>
    </div>
  );
};
