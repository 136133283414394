import React from "react";
import Icon, { IconProps } from "./Icon";

export default function EnvelopeIcon({
  viewBox = "0 0 24 24",
  width = 24,
  height = 24,
  fill = 'none',
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} viewBox={viewBox} fill={fill} {...iconProps} >
     <g clipPath="url(#clip0_43_451)">
        <path d="M13.75 4.75H4.75C3.64543 4.75 2.75 5.64543 2.75 6.75V17.25C2.75 18.3546 3.64543 19.25 4.75 19.25H19.25C20.3546 19.25 21.25 18.3546 21.25 17.25V12.25M2.99805 9.25C5.50528 11.1337 8.62204 12.25 11.9995 12.25C13.5675 12.25 15.0793 12.0094 16.5 11.5631M23.25 6C23.25 7.79493 21.7949 9.25 20 9.25C18.2051 9.25 16.75 7.79493 16.75 6C16.75 4.20507 18.2051 2.75 20 2.75C21.7949 2.75 23.25 4.20507 23.25 6Z" stroke="#81FFBB" strokeWidth="2.25" stroke-linecap="round" />
      </g>
      <defs>
        <clipPath id="clip0_43_451">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
}
