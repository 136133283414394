import React, { useMemo } from "react";
import { useId } from "react-aria";

export const useDiagonalMask = () => {
  const patternId = useId();
  const maskId = useId();
  const maskUrl = `url(#${maskId})`;

  const mask = useMemo(
    () => (
      <>
        <pattern
          id={patternId}
          width="4"
          height="4"
          patternUnits="userSpaceOnUse"
          patternTransform="rotate(45)"
        >
          <rect width="2" height="4" transform="translate(0,0)" fill="white"></rect>
        </pattern>

        <mask id={maskId}>
          <rect x="0" y="0" width="100%" height="100%" fill={`url(#${patternId})`} />
        </mask>
      </>
    ),
    [maskId, patternId]
  );

  // TODO getMask based on date
  // const getMask = useCallback(() => null, []);

  return {
    maskId,
    maskUrl,
    mask,
  };
};
