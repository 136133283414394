import React from "react";

import { ChartDatePoint } from "graphql/types";

import { Cell, Bar, ComposedChart, ResponsiveContainer, Tooltip } from "recharts";

import { SpendGraphToolTip } from "./GraphToolTip";
import { EmptyGraph, EmptyGraphVariant } from "./EmptyGraph";

import { color } from "ve";

export const SpendWithCompositionBarGraph = ({ data }: { data?: ChartDatePoint[] }) => {
  if (!data || data.length === 0) {
    return <EmptyGraph variant={EmptyGraphVariant.BARS_THICK} text="Not enough data to graph" />;
  }

  const lastElementIndex = data.length - 1;

  const mappedData = data.map((x) => ({
    date: x.date,
    payrollValue: x.breakdowns
      ? parseFloat(x.breakdowns.find((x) => x && x.key === "payroll")!.value || "0")
      : 0,
    nonPayrollValue: x.breakdowns
      ? parseFloat(x.breakdowns.find((x) => x && x.key === "non-payroll")!.value || "0")
      : 0,
  }));

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart data={mappedData}>
        <defs>
          <linearGradient id="colorUv_purple" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={color.purple500} stopOpacity={1.0} />
            <stop offset="100%" stopColor={color.purple900} stopOpacity={1.0} />
          </linearGradient>
          <linearGradient id="colorUv_pink" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={color.pink500} stopOpacity={1.0} />
            <stop offset="100%" stopColor={color.mauve800} stopOpacity={1.0} />
          </linearGradient>

          <pattern
            id="diagPattern"
            width="4"
            height="4"
            patternUnits="userSpaceOnUse"
            patternTransform="rotate(45)"
          >
            <rect width="2" height="4" fill="white" />
          </pattern>

          <mask id="maskWithPattern">
            <rect x="0" y="0" width="100%" height="100%" fill="url(#diagPattern)" />
          </mask>
        </defs>

        <Bar
          isAnimationActive={false}
          yAxisId="spend"
          name="spend"
          radius={[4, 4, 4, 4]}
          stackId="a"
          dataKey="payrollValue"
        >
          {data.map((_, index) => (
            <Cell
              key={`cell-${index}`}
              fill={"url(#colorUv_purple)"}
              mask={index === lastElementIndex ? "url(#maskWithPattern)" : ""}
            />
          ))}
        </Bar>
        <Bar
          isAnimationActive={false}
          yAxisId="spend"
          name="spend"
          radius={[4, 4, 4, 4]}
          stackId="a"
          dataKey="nonPayrollValue"
        >
          {data.map((_, index) => (
            <Cell
              key={`cell-${index}`}
              fill={"url(#colorUv_pink)"}
              mask={index === lastElementIndex ? "url(#maskWithPattern)" : ""}
            />
          ))}
        </Bar>

        <Tooltip
          wrapperStyle={{ outline: "none" }}
          cursor={false}
          content={({ active, payload }) => {
            if (active && payload && payload.length) {
              return (
                <SpendGraphToolTip
                  date={payload[0].payload.date}
                  payrollValue={payload[0].payload.payrollValue}
                  nonPayrollValue={payload[0].payload.nonPayrollValue}
                />
              );
            }

            return null;
          }}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
