import React from "react";

import { CalendarDateString } from "@puzzle/utils";

import { color, S, Box, Text, vars } from "ve";

import UpgradeButton from "components/featureGate/UpgradeButton";
import { DeltaIndicator } from "./DeltaIndicator";
import { GraphDateRange } from "./GraphDateRange";

export const BasicGraphTileContainer = ({
  title,
  valueString,
  percentageIncrease,
  isFeatureGated,
  startDate,
  endDate,
  children,
  initialIngestOngoing,
}: {
  title: string;
  valueString?: string;
  percentageIncrease?: number;
  isFeatureGated?: boolean;
  startDate?: CalendarDateString;
  endDate?: CalendarDateString;
  children: React.ReactNode;
  initialIngestOngoing?: boolean;
}) => {
  return (
    <Box
      css={{
        position: "relative",
        border: "1px solid",
        borderColor: color.mauve600,
        borderRadius: vars.radii[1],
        paddingBottom: S["0h"], // Possibly not the right place for this?
        zIndex: 0,
      }}
    >
      {isFeatureGated && (
        <Box
          css={{
            position: "absolute",
            top: "16px",
            left: "16px",
            right: 0,
            bottom: 0,
            zIndex: 1,
          }}
        >
          <Text variant="headingS" color="gray300" weight="bold">
            {title}
          </Text>
          <UpgradeButton
            title="Upgrade to view"
            variant="gold-outline"
            css={{
              position: "absolute",
              left: 0,
              right: 0,
              top: "30%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </Box>
      )}

      <Box
        css={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",

          backgroundColor: isFeatureGated ? color.blurBackground : color.mauve800,
          filter: isFeatureGated ? "blur(12.5px)" : "none",
        }}
      >
        <Box
          css={{
            height: initialIngestOngoing ? "auto" : "118px",
            padding: S["2"],
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text variant="headingS" color="gray300" weight="bold">
            {title}
          </Text>
          {!initialIngestOngoing && (
            <Box css={{ marginTop: S["1h"], display: "flex", flexDirection: "row", gap: S.$1 }}>
              <Text variant="heading2" color="white" weight="bold">
                {valueString !== undefined && valueString}
              </Text>
              {percentageIncrease !== undefined && <DeltaIndicator value={percentageIncrease} />}
            </Box>
          )}
        </Box>
        <Box css={{ width: "100%", flex: "1" }}>{children}</Box>
        <GraphDateRange startDate={startDate} endDate={endDate} />
      </Box>
    </Box>
  );
};
