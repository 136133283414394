import React from "react";

export const LineIcon = ({ color }: { color?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path stroke={color} strokeLinecap="round" strokeWidth="2" d="M3 10L17 10"></path>
      <path
        // TODO make this hollow instead? flatten this shape
        fill="#1B1C29"
        stroke={color}
        strokeWidth="1.5"
        d="M13.25 10a3.25 3.25 0 11-6.5 0 3.25 3.25 0 016.5 0z"
      ></path>
    </svg>
  );
};
