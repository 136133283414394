import React from "react";
import { BasicGraphTileContainer } from "./BasicGraphTileContainer";
import Calculating from "./Calculating";
import LoadingMetrics from "./LoadingMetrics";
import { ChartDatePoint } from "graphql/types";
import { formatMoney } from "@puzzle/utils";
import { BurnComparisonBarGraph } from "./BurnComparisonGraph";
import { EmptyGraph, EmptyGraphVariant } from "./EmptyGraph";

interface BasicGraphTileProps {
  title: string;
  alternativeTitle: string;
  loading?: boolean;
  initialIngestOngoing?: boolean;
  isFeatureGated?: boolean;
  data?: ChartDatePoint[];
}

export const BurnComparisonTile = ({
  initialIngestOngoing,
  loading,
  title,
  alternativeTitle,
  data,
  isFeatureGated,
}: BasicGraphTileProps) => {
  let formattedValue;
  if (data && data.length >= 0) {
    const lastValue = data
      .slice(-2)
      .toReversed()
      .at(0)
      ?.values.find((x) => x.key === "net-burn")?.value;
    formattedValue = formatMoney({ amount: parseFloat(lastValue || "0") }, { truncateValue: true });
  }

  // For this case, last month needs to be first, and we only need
  // current month and previous month.
  const reversedData = data?.slice(-2).toReversed();

  // This is for reversing the bar direction on bar graph.
  // What we want is that, if we have positive values, show them going to the right,
  // but if we don't show negative values going to the right.
  const hasSomePositiveNetBurn = reversedData?.some((chartDatePoint) => {
    return chartDatePoint.values.some((chartPoint) => {
      return chartPoint.key === "net-burn" && parseFloat(chartPoint.value) >= 0.0;
    });
  });

  // This is just for title manipulation. If all values are positive,
  // then it won't be a Burn comparison chart no more, it will be a Cash Generated chart.
  const hasOnlyPositiveNetBurn = reversedData?.every((chartDatePoint) => {
    return chartDatePoint.values.some((chartPoint) => {
      return chartPoint.key === "net-burn" && parseFloat(chartPoint.value) >= 0.0;
    });
  });

  return (
    <BasicGraphTileContainer
      title={hasOnlyPositiveNetBurn ? alternativeTitle : title}
      valueString={formattedValue}
      isFeatureGated={isFeatureGated}
      initialIngestOngoing={initialIngestOngoing}
    >
      {initialIngestOngoing ? (
        <>
          <Calculating css={{ marginLeft: "$2" }} />
          <EmptyGraph variant={EmptyGraphVariant.BARS_THICK} />
        </>
      ) : loading ? (
        <div style={{ paddingLeft: "16px" }}>
          <LoadingMetrics />
        </div>
      ) : (
        <BurnComparisonBarGraph data={reversedData} reverseBars={hasSomePositiveNetBurn} />
      )}
    </BasicGraphTileContainer>
  );
};
