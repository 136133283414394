import { create } from "zustand";

interface IntroTourState {
  isToolTipTourShown: boolean;
  showToolTipTour: () => void;
  hideToolTipTour: () => void;
}

export const useIntroTourStore = create<IntroTourState>()((set) => ({
  isToolTipTourShown: false,
  showToolTipTour: () => set({ isToolTipTourShown: true }),
  hideToolTipTour: () => set({ isToolTipTourShown: false }),
}));
