import React, { useCallback, useMemo } from "react";
import { styled, Tabs } from "@puzzle/ui";

import Analytics, { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import { DynamicReportType, LedgerView } from "graphql/types";
import { BreakoutProvider } from "./BreakoutProvider";
import { HighlightProvider } from "./HighlightProvider";
import { DeltaProvider } from "./Filters/DeltaProvider";
import LoadingReport from "./LoadingReport";
import ReportContent from "./ReportContent";
import { useStickyReportContext, TABS } from "components/reports/StickyReportContext";
import useLedgerReport from "./useLedgerReport";
import { useActiveCompany } from "components/companies";
import { useReportContext } from "components/reports/ReportContext";
import IngestingReport from "./IngestingReport";
import { SpotlightFeatureGateModal } from "components/featureGate/SpotlightFeatureGateModal";
import { useFeatureGateStore } from "components/featureGate/featureGateStore";
import ReportContentFilters from "./ReportContentFilters";

const Wrapper = styled("div", {
  border: "1px solid #383147",
  borderRadius: "$1",
  overflow: "hidden",

  "@media print": {
    border: "none",
  },
});

const Reports = () => {
  const { company, initialIngestCompleted } = useActiveCompany<true>();
  const {
    timePeriods,
    activeGroupBy,
    stickyOptions: { view, activeTab, filter },
    setStickyOptions,
  } = useStickyReportContext();

  const { classificationsData } = useReportContext();
  const tab = useMemo(() => TABS.find((t) => t.value === activeTab) ?? TABS[0], [activeTab]);
  const type = tab.value as DynamicReportType;

  const enableClassesAndDepts =
    (isPosthogFeatureFlagEnabled(FeatureFlag.ClassesAndDeptsM1) ?? false) &&
    type === DynamicReportType.ProfitAndLoss;

  const setTabValue = useCallback(
    (value: string) => {
      Analytics.dashboardReportTabChanged({ reportType: value as DynamicReportType });
      setStickyOptions({ activeTab: value as DynamicReportType });
      if (value === DynamicReportType.CashActivityReport) {
        setStickyOptions({ view: LedgerView.Cash });
      }
    },
    [setStickyOptions]
  );

  const report = useLedgerReport({
    companyId: company.id,
    timePeriods,
    type,
    view,
    groupBy: activeGroupBy,
    classifications: classificationsData,
    filter,
  });

  // We need the report data outside of the tab layout.
  // We need the high watermark token for the CSV download to make sure it's the same data.
  // We essentially always return the same thing, but Apollo will still cache.
  const finalTabs = useMemo(() => {
    return TABS.map((tab) => ({
      ...tab,
      content: !initialIngestCompleted ? (
        <IngestingReport />
      ) : report.loading ? (
        <>
          <ReportContentFilters
            reportType={type}
            enableClassesAndDepts={enableClassesAndDepts}
            tab={tab}
          />
          <LoadingReport />
        </>
      ) : (
        <>
          <ReportContentFilters
            reportType={type}
            enableClassesAndDepts={enableClassesAndDepts}
            tab={tab}
          />
          <ReportContent report={report} reportType={type} />
        </>
      ),
    }));
  }, [report, type, initialIngestCompleted, enableClassesAndDepts]);

  const tabs = useMemo(() => {
    return (
      <>
        <Tabs
          variant="folder"
          items={finalTabs}
          value={activeTab}
          onValueChange={setTabValue}
          css={{ marginTop: "$1" }}
          data-testid="ReportTabs"
        />
      </>
    );
  }, [finalTabs, activeTab, setTabValue]);

  return (
    <HighlightProvider report={report}>
      <BreakoutProvider reportType={type}>{tabs}</BreakoutProvider>
    </HighlightProvider>
  );
};

const ReportsContainer = ({ className }: { className?: string }) => {
  const isSpotlightFeatureGateModalShown = useFeatureGateStore(
    (state) => state.isSpotlightFeatureGateModalShown
  );
  const hideSpotlightFeatureGateModal = useFeatureGateStore(
    (state) => state.hideSpotlightFeatureGateModal
  );

  return (
    <Wrapper className={className}>
      <DeltaProvider>
        <Reports />
      </DeltaProvider>

      <SpotlightFeatureGateModal
        open={isSpotlightFeatureGateModalShown}
        onClose={() => {
          hideSpotlightFeatureGateModal();
        }}
      />
    </Wrapper>
  );
};

export default ReportsContainer;
