import { create } from "zustand";

interface FeatureGateState {
  isSpotlightFeatureGateModalShown: boolean;
  showSpotlightFeatureGateModal: () => void;
  hideSpotlightFeatureGateModal: () => void;
}

export const useFeatureGateStore = create<FeatureGateState>()((set) => ({
  isSpotlightFeatureGateModalShown: false,
  showSpotlightFeatureGateModal: () => set({ isSpotlightFeatureGateModalShown: true }),
  hideSpotlightFeatureGateModal: () => set({ isSpotlightFeatureGateModalShown: false }),
}));
