import React from "react";

import { S, Button, Text } from "ve";

import {
  TopTransactionsModalContent,
  TopTransactionsStep,
} from "components/dashboard/Transactions/TopTransactionsModal/TopTransactionsModal";

import { UpdateCategoryMetricsLocations } from "components/dashboard/Transactions/hooks/useSingleTransaction";

import { rootStyle, titleStyle } from "./Categorize.css";
import { usePOMChecklistUpdate } from "./usePOMChecklist";

export const Categorize = ({ onContinue, onClose }: { onContinue?: () => void, onClose?: () => void }) => {

  const { markCategorizationModelAsChecked } = usePOMChecklistUpdate();

  const handleClose = async () => {
    await markCategorizationModelAsChecked();
    if(onClose) {
      onClose();
    }
    if(onContinue) {
      onContinue();
    }
  };

  return (
    <div className={rootStyle}>
      <div className={titleStyle}>
        <Text variant="headingM" color="white">
          Categorize high value transactions
        </Text>
      </div>

      <TopTransactionsModalContent
        open={true}
        setOpen={() => {}}
        initialStep={TopTransactionsStep.Categorize}
        metricsLocation={UpdateCategoryMetricsLocations.PostOnboardModal}
      />
      <Button
        onClick={handleClose}
        css={{ alignSelf: "flex-end", marginTop: "auto", marginBottom: S.$4, marginRight: S.$3 }}
      >
        {onClose ? 'Done' : 'Continue'}
      </Button>
    </div>
  );
};
