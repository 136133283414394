import React, { useRef } from "react";

import useSelf from "components/users/useSelf";
import { POMChecklistState } from "./usePOMChecklist";
import { Checkbox } from "@puzzle/ui";

import { Button, Loader, Text } from "ve";

import {
  rootStyle,
  middleTextContainerStyle,
  buttonsContainerStyle,
} from "./LeftPanel.css";

interface LeftPanelProps {
  setPanel: (val: number) => void;
  activeButtonIndex: number;
  checklistState: POMChecklistState;
  isInviteeVariant?: boolean;
}

const SimpleButton = ({
  text,
  onClick,
  isActive,
  isChecked,
  isLoading,
}: {
  text: string;
  onClick: () => void;
  isActive: boolean;
  isChecked: boolean;
  isLoading?: boolean;
}) => {
  const optCss = isActive ? { borderColor: "white" } : {};

  return (
    <Button
      variant="secondary"
      fullWidth={true}
      fullWidthContent={true}
      onClick={onClick}
      css={optCss}
      prefixElement={
        isLoading ? <Loader size={14} css={{ width: 14, height: 14 }} />
        : <Checkbox interactive={false} checked={isChecked} />
      }
    >
      <Text variant="bodyM" color="white">
        {text}
      </Text>
    </Button>
  );
};

export const ButtonListOwner = ({
  setPanel,
  activeButtonIndex,
  isEmbedVariant,
  checklistState,
}: {
  setPanel: (val: number) => void;
  activeButtonIndex: number;
  isEmbedVariant: boolean;
  checklistState: POMChecklistState;
}) => {
  return (
    <div className={buttonsContainerStyle}>
      <SimpleButton
        text="Preparing your Financials"
        onClick={() => setPanel(0)}
        isActive={activeButtonIndex === 0}
        isChecked={checklistState.isPreparingYourFinancialsChecked}
        isLoading={!checklistState.isPreparingYourFinancialsChecked}
      />
      <SimpleButton
        text="Set your bookkeeping plan"
        onClick={() => setPanel(1)}
        isActive={activeButtonIndex === 1}
        isChecked={checklistState?.isBookkeepingPlanChecked}
      />
      <SimpleButton
        text="Book your concierge onboarding call"
        onClick={() => setPanel(2)}
        isActive={activeButtonIndex === 2}
        isChecked={checklistState?.isOnboardingCallChecked}
      />
      <SimpleButton
        text="Train your categorization model"
        onClick={() => setPanel(3)}
        isActive={activeButtonIndex === 3}
        isChecked={checklistState?.isCategorizationModelChecked}
      />
    </div>
  );
};

export const ButtonListInvitee = ({
  setPanel,
  activeButtonIndex,
  checklistState,
  isEmbedVariant
}: {
  setPanel: (val: number) => void;
  activeButtonIndex: number;
  isEmbedVariant: boolean;
  checklistState: POMChecklistState;
}) => {
  const keepCategorizeRenderedRef = useRef(!checklistState.isCategorizationModelChecked);
  return (
    <div className={buttonsContainerStyle}>
      <SimpleButton
        text="What's your position?"
        onClick={() => setPanel(0)}
        isActive={activeButtonIndex === 0}
        isChecked={checklistState?.isPositionChecked}
      />
      {keepCategorizeRenderedRef.current && <SimpleButton
        text="Train your categorization model"
        onClick={() => setPanel(1)}
        isActive={activeButtonIndex === 1}
        isChecked={checklistState?.isCategorizationModelChecked}
      />}
      <SimpleButton
        text={isEmbedVariant ? "Take a tour of the dashboard" : "Get a sneak peek of Puzzle"}
        onClick={() => setPanel(keepCategorizeRenderedRef.current ? 2 : 1)}
        isActive={activeButtonIndex === (keepCategorizeRenderedRef.current ? 2 : 1)}
        isChecked={
          isEmbedVariant
            ? checklistState?.isDashboardTourChecked
            : checklistState?.isSneakPeekChecked
        }
      />
    </div>
  );
};

export const LeftPanel = ({
  setPanel,
  activeButtonIndex,
  checklistState,
  isInviteeVariant,
}: LeftPanelProps) => {
  const { self } = useSelf();
  const name = self?.name;
  const welcomeMessage = name ? `Hi ${name}, welcome to Puzzle!` : "Hi, welcome to Puzzle!";

  const ButtonList = isInviteeVariant ? ButtonListInvitee : ButtonListOwner;

  return (
    <div className={rootStyle}>
      <Text variant="heading2" color="gray200">
        {welcomeMessage}
      </Text>
      <div className={middleTextContainerStyle}>
        <Text variant="headingS" color="gray300">
          Here are a few things we recommend you do to get started:
        </Text>
      </div>
      <ButtonList
        setPanel={setPanel}
        activeButtonIndex={activeButtonIndex}
        isEmbedVariant={false}
        checklistState={checklistState}
      />
    </div>
  );
};
